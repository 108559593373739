.workflow {
    margin-top: 100px;
    padding: 20px 8px;
    background-color: #eef0f4 !important;
    border-radius: 8px;
    box-shadow: none !important;
    background-color: #fff;
}
.workflowinnergrid {
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(
        to right,
        rgba(1, 174, 143, 0),
        #04ab93 7%,
        #2c83bd 91%,
        rgba(48, 127, 193, 0)
    );
    border-image-slice: 1;
    margin-bottom: 20px !important;
    max-width: 32% !important;
    flex-basis: 32% !important;
    border-radius: 8px;
     box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    overflow: hidden;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
}
.workflowinnergrid h2 {
    margin: 0 0 16px;
    padding: 30px 22px;
    border-radius: 8px;
    font-size: 18px;
    background-image: url(/src/assets/images/workflowmask.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.workflowinnergrid p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #5d6870;
    margin: 0 0 16px;
    padding-left: 12px;
}
.workflow .MuiGrid-container {
    justify-content: space-between;
}
.gridtitle {
    background-image: linear-gradient(103deg, #faf8f9 5%, #eff3f8 95%);
}
.workflowinnergrid:hover .gridtitle {
    background-image: linear-gradient(103deg, #019cae, #307fc1) ;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
}
.workflowinnergrid:hover .gridtitle h2 {
    color: #fff;
}
.workflowdescription {
    display: flex;
    padding: 0 22px;
}
.workflowdescription svg{
    color:#5d6870;
}
.workflowdescription.active{
display: none;
}

.workflowinnergrid:hover .workflowdescription.active{
    display: flex !important;
}
.workflowinnergrid:hover .inActive{
    display: none !important;
}
@media screen and (max-width: 1400px) {
    .workflowinnergrid{
        max-width: 31% !important;
    }
}
@media screen and (max-width: 1200px) {
    .workflowinnergrid{
        max-width: 48% !important;
        flex-basis: 48% !important;

    }
}
@media screen and (max-width: 768px) {
    .workflow {
        margin-top: 15px;
    }
    .workflowinnergrid {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
}

.disable-workflow {
    pointer-events: none;
    opacity: 0.5;
}
