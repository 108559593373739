#player {
    border: 5px solid rgba(0,0,0,.1607843137254902);
    width: 83%;
    margin: 5%;
    padding: 2px
}

#display {
    position: relative
}

#player .notification-container {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0
}

#player .seek-notification {
    color: #fff;
    background: rgba(0,0,0,.75);
    display: none;
    width: 100%;
    height: 100%
}

#player.seeking .seek-notification {
    display: table
}

#player .seek-notification p {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-family: sans-serif
}

#player .controls {
    width: 100%;
    display: -ms-flexbox;
    -ms-flex-align: center;
    -ms-flex-direction: row;
    display: -moz-box;
    -moz-box-align: center;
    -moz-box-orient: horizontal;
    display: -webkit-box;
    -webkit-box-align: center;
    -webkit-box-orient: horizontal;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-flex-direction: row;
    display: flex;
    align-items: center;
    flex-direction: row
}

#player .controls > * {
    margin: .25em
}

#player .controls #position-slider {
    -ms-flex: 1 1 auto;
    -moz-box-flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto
}

#player .controls #play-pause {
    margin-left: 0;
    min-width: 5em
}

#player .controls #duration, #player .controls #position {
    font-family: monospace
}

#player .controls #duration {
    margin-right: 0
}
