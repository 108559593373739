.textfield {
    position: relative;
}

.textfield .picker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    margin-top: 0;
    width: 100%;
    z-index: 999;
}

.textfield ::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 1;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    background: transparent;
    z-index: 999;
}
